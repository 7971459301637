<div [ngClass]="{ 'input-group-short': !fullWidth, 'input-group': fullWidth }">
  <p-calendar
    [clearButtonStyleClass]="'sb-calendar-hide-clear'"
    [styleClass]="styleClass"
    [class]="class"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [inputStyleClass]="'aria-invalid:border-red-500'"
    panelStyleClass="{{ appendTo === 'body' ? 'sb-calendar-append-body ' : '' }}"
    [(ngModel)]="date"
    [disabled]="disabled"
    [showButtonBar]="showButtonBar"
    [firstDayOfWeek]="1"
    [showClear]="false"
    [numberOfMonths]="numberOfMonths"
    [showWeek]="true"
    [showIcon]="true"
    [dateFormat]="dateFormat"
    [appendTo]="appendTo"
    [hideOnDateTimeSelect]="true"
    [inline]="inline"
    (ngModelChange)="onChangeInput(date)"
    [ngModelOptions]="{ standalone: true }"
  >
  </p-calendar>
</div>
