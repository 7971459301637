import { CommonModule } from '@angular/common';
import { Component, forwardRef, HostBinding, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { addDays, isValid, startOfDay, subDays } from 'date-fns';
import { CalendarModule } from 'primeng/calendar';

import { getPrimeNGDateFormat } from '../../../../+authenticated/shared/locale/locale.helper';
import { format, parseDate } from '../../../date.helper';
import { DefaultDateFormat } from '../sb-calendar.model';

export const DATE_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DateInputComponent),
  multi: true,
};

@Component({
  selector: 'date-input',
  templateUrl: 'date-input.component.html',
  providers: [DATE_INPUT_CONTROL_VALUE_ACCESSOR],
  standalone: true,
  imports: [CommonModule, FormsModule, CalendarModule],
})
export class DateInputComponent implements ControlValueAccessor, OnInit {
  @Input()
  public appendTo: string;
  @Input()
  public showButtonBar = true;
  @Input()
  public inline = false;
  @Input()
  public disabled = false;
  @Input()
  public numberOfMonths = 1;
  @Input()
  public styleClass: string;

  @Input()
  public class: string;

  private _minDate;
  @Input()
  public set minDate(value) {
    if (!value) {
      this._minDate = null;
      return;
    }
    this._minDate = startOfDay(parseDate(value));
  }

  public get minDate() {
    return this._minDate;
  }

  private _maxDate;
  @Input()
  public set maxDate(value) {
    if (!value) {
      this._maxDate = null;
      return;
    }
    this._maxDate = startOfDay(parseDate(value));
  }
  public get maxDate() {
    return this._maxDate;
  }
  @Input()
  public defaultDate: Date;

  @Input()
  @HostBinding('class.date-input--full-width')
  public fullWidth = false;

  public date: Date;
  public value: string;

  public dateFormat: string;

  // internal functions to call when ControlValueAccessor
  // gets called
  public onTouched: () => void;
  public onModelChange: (date: string) => void;

  public constructor(private translateService: TranslateService) {}

  public ngOnInit() {
    this.dateFormat = getPrimeNGDateFormat(this.translateService.currentLang) ?? DefaultDateFormat;
  }

  // Change via typing
  public onChangeInput(date: Date) {
    this.value = this.dateToValue(date);
    this.date = date;
    this.onChange();
  }

  private onChange() {
    this.onModelChange(this.value);
  }

  public changeDate(direction: string, event: KeyboardEvent) {
    event.preventDefault();

    if (!this.value) {
      return this.updateValue(new Date());
    }

    const currentDate = parseDate(this.value);
    if (!isValid(currentDate)) {
      return this.updateValue(new Date());
    }

    if (direction === 'up') {
      return this.updateValue(addDays(currentDate, 1));
    }

    return this.updateValue(subDays(currentDate, 1));
  }

  public updateValue(date: Date) {
    const startOfDayDate = startOfDay(date);

    //validate agains min / max
    if (this.minDate && startOfDayDate < parseDate(this.minDate)) {
      return;
    }
    if (this.maxDate && startOfDayDate > parseDate(this.maxDate)) {
      return;
    }

    this.writeValue(format(startOfDayDate, 'yyyy-MM-dd'));
    this.onChange();
  }

  // called by the reactive form control
  public registerOnChange(fn: (date: string) => void) {
    // assigns to our internal model change method
    this.onModelChange = fn;
  }

  public registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

  // writes the value to the local component
  // that binds to the "value"
  public writeValue(value: string) {
    if (value === null) {
      return;
    }

    this.value = value;

    if (value === '') {
      this.date = undefined;
    } else {
      this.date = parseDate(value);
    }
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public dateToValue(date: Date | null | ''): string {
    if (!date || (typeof date === 'string' && date === '')) {
      return '';
    }

    return format(parseDate(date), 'yyyy-MM-dd');
  }
}
